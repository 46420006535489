(function(window) {
  'use strict';

  var $ = window.$ || {};

  $('.js-slide').slick({
    autoplay: true,
    autoplaySpeed: 5000,
    drragable: false,
    fade: true,
    pauseOnHover: false,
    arrows: false,
    speed: 2000
  });
})(this);
